var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-modal-new',{attrs:{"name":"modal-update-company","title":'AIPT.MODALS.UPDATE_COMPANY.TITLE',"confirm-action-label":'AIPT.MODALS.UPDATE_COMPANY.SUBMIT',"confirm-action-disabled":!_vm.isSubmitEnabled},on:{"handle-confirm":_vm.updateCompany,"on-show":_vm.setData,"on-close":_vm.resetData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"modal-update-company__wrap"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('AIPT.MODALS.UPDATE_COMPANY.BODY'))}}),_c('app-input-new',{ref:"firstNameRef",attrs:{"autocomplete":"first-name","name":"first_name","type":"text","label":_vm.$t(
                        'PREONBOARDING.ACCOUNT_DETAILS.FORM.FIELDS.FIRST_NAME.LABEL'
                    ),"placeholder":_vm.$t(
                        'PREONBOARDING.ACCOUNT_DETAILS.FORM.FIELDS.FIRST_NAME.PLACEHOLDER'
                    ),"error":_vm.firstError('first_name')},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('app-input-new',{attrs:{"autocomplete":"last-name","name":"last_name","type":"text","label":_vm.$t(
                        'PREONBOARDING.ACCOUNT_DETAILS.FORM.FIELDS.LAST_NAME.LABEL'
                    ),"placeholder":_vm.$t(
                        'PREONBOARDING.ACCOUNT_DETAILS.FORM.FIELDS.LAST_NAME.PLACEHOLDER'
                    ),"error":_vm.firstError('last_name')},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('app-select-new',{attrs:{"name":"country","label-content":_vm.$t(
                        'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.COUNTRY.LABEL'
                    ),"placeholder":_vm.$t(
                        'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.COUNTRY.PLACEHOLDER'
                    ),"options":_vm.mappedCountries,"clearable":true,"searchable":true,"error":_vm.firstError('country_id')},model:{value:(_vm.form.country_id),callback:function ($$v) {_vm.$set(_vm.form, "country_id", $$v)},expression:"form.country_id"}}),_c('app-input-new',{attrs:{"name":"jobTitle","label":_vm.$t(
                        'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.POSITION.LABEL'
                    ),"placeholder":_vm.$t(
                        'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.POSITION.PLACEHOLDER'
                    ),"error":_vm.firstError('jobTitle')},model:{value:(_vm.form.jobTitle),callback:function ($$v) {_vm.$set(_vm.form, "jobTitle", $$v)},expression:"form.jobTitle"}}),_c('app-select-new',{attrs:{"name":"selectedPersona","label-content":_vm.$t('PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.ROLE.LABEL'),"placeholder":_vm.$t(
                        'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.ROLE.SELECT_PLACEHOLDER'
                    ),"options":_vm.mappedPersonas,"clearable":true,"searchable":true,"error":_vm.firstError('persona')},model:{value:(_vm.form.selectedPersona),callback:function ($$v) {_vm.$set(_vm.form, "selectedPersona", $$v)},expression:"form.selectedPersona"}}),(_vm.isInputPersonaVisible)?_c('app-input-new',{attrs:{"name":"persona","placeholder":_vm.$t(
                        'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.ROLE.INPUT_PLACEHOLDER'
                    ),"error":_vm.firstError('persona')},model:{value:(_vm.persona),callback:function ($$v) {_vm.persona=$$v},expression:"persona"}}):_vm._e(),(_vm.isLoading)?_c('loader-overlay',{attrs:{"is-absolute":true}}):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }