import AIPT from './de/fe_aipt.json'
import USER_MANAGEMENT from './de/fe_user_management.json'
import TRAININGS from './de/fe_trainings.json'
import SECURITY_OBJECTIVES from './de/fe_security_objectives.json'
import COMPLIANCE from './de/fe_compliance.json'
import TRAINING_PROGRAM from './de/fe_training_programs.json'
import ACCOUNT from './de/account.json'
import ACCOUNT_SETTINGS from './de/account_settings.json'
import APP from './de/app.json'
import EXPERTA from './de/experta.json'
import COMMON from './de/fe_common.json'
import COMPANY_SETTINGS from './de/company_settings.json'
import CONSULTANT from './de/consultant.json'
import DISCUSSION from './de/discussion.json'
import DOCUMENT_MANAGEMENT_SYSTEM from './de/document_management_system.json'
import DOCUMENT_WIZARD from './de/document_wizard.json'
import ERRORS from './de/fe_errors.json'
import FORM from './de/form.json'
import ISO_27001 from './de/iso_27001.json'
import LANGUAGES from './de/languages.json'
import MY_WORK from './de/my_work.json'
import ONBOARDING from './de/onboarding.json'
import ORGANIZATION from './de/organization.json'
import PREONBOARDING from './de/fe_preonboarding.json'
import PRICING from './de/pricing.json'
import REGISTER_WIZARD from './de/register_wizard.json'
import CORRECTIVE_ACTIONS from './de/corrective_actions.json'
import INCIDENTS from './de/incidents.json'
import INTERNAL_AUDITS from './de/internal_audits.json'
import NONCONFORMITIES from './de/nonconformities.json'
import REQUIREMENTS from './de/fe_requirements.json'
import RISKS from './de/fe_risks.json'
import SOA from './de/soa.json'
import TRAINING_MODULE from './de/training_module.json'
import REGISTERS_DATA from './de/registers.json'
import REGISTERS_AND_MODULES from './de/registers_and_modules.json'
import REPORTING from './de/reporting.json'
import RESOURCE from './de/resource.json'
import SETTINGS from './de/settings.json'
import SLACK from './de/slack.json'
import STEP_INPUTS from './de/step_inputs.json'
import TASKS from './de/fe_tasks.json'
import VALIDATION from './de/fe_validation.json'
import REGULATION_DATA from './de/regulation.json'
import CUSTOM_STEP from './de/custom_step.json'
import SIMPLE_STEP from './de/simple_step.json'
import STEP_0 from './de/step0.json'
import STEP_1 from './de/step1.json'
import STEP_2 from './de/step2.json'
import STEP_3 from './de/step3.json'
import STEP_4 from './de/step4.json'
import STEP_5 from './de/step5.json'
import STEP_6 from './de/step6.json'
import STEP_7 from './de/step7.json'
import STEP_8 from './de/step8.json'
import STEP_9 from './de/step9.json'
import STEP_10 from './de/step10.json'
import STEP_11 from './de/step11.json'
import STEP_12 from './de/step12.json'
import STEP_13 from './de/step13.json'
import STEP_14 from './de/step14.json'
import STEP_15 from './de/step15.json'
import STEP_16 from './de/step16.json'
import STEP_17 from './de/step17.json'
import STEP_18 from './de/step18.json'
import STEP_19 from './de/step19.json'
import STEP_20 from './de/step20.json'
import STEP_21 from './de/step21.json'
import STEP_22 from './de/step22.json'
import STEP_23 from './de/step23.json'
import STEP_24 from './de/step24.json'
import STEP_25 from './de/step25.json'
import STEP_26 from './de/step26.json'
import STEP_27 from './de/step27.json'
import STEP_28 from './de/step28.json'
import STEP_29 from './de/step29.json'
import STEP_30 from './de/step30.json'
import STEP_31 from './de/step31.json'
import STEP_32 from './de/step32.json'
import STEP_33 from './de/step33.json'
import STEP_34 from './de/step34.json'
import STEP_37 from './de/step37.json'
import STEP_39 from './de/step39.json'
import STEP_40 from './de/step40.json'
import STEP_42 from './de/step42.json'
import STEP_66 from './de/step66.json'
import STEP_77 from './de/step77.json'
import STEP_88 from './de/step88.json'
import STEP_99 from './de/step99.json'

const REGULATION = {
    ...REGULATION_DATA,
    ...CUSTOM_STEP,
    ...SIMPLE_STEP,
    ...STEP_0,
    ...STEP_1,
    ...STEP_2,
    ...STEP_3,
    ...STEP_4,
    ...STEP_5,
    ...STEP_6,
    ...STEP_7,
    ...STEP_8,
    ...STEP_9,
    ...STEP_10,
    ...STEP_11,
    ...STEP_12,
    ...STEP_13,
    ...STEP_14,
    ...STEP_15,
    ...STEP_16,
    ...STEP_17,
    ...STEP_18,
    ...STEP_19,
    ...STEP_20,
    ...STEP_21,
    ...STEP_22,
    ...STEP_23,
    ...STEP_24,
    ...STEP_25,
    ...STEP_26,
    ...STEP_27,
    ...STEP_28,
    ...STEP_29,
    ...STEP_30,
    ...STEP_31,
    ...STEP_32,
    ...STEP_33,
    ...STEP_34,
    ...STEP_37,
    ...STEP_39,
    ...STEP_40,
    ...STEP_42,
    ...STEP_66,
    ...STEP_77,
    ...STEP_88,
    ...STEP_99
}

const REGISTERS = {
    CORRECTIVE_ACTIONS,
    INCIDENTS,
    INTERNAL_AUDITS,
    NONCONFORMITIES,
    RISKS,
    SOA,
    TRAINING_MODULE,
    ...REGISTERS_DATA
}

export const DE = {
    AIPT,
    TRAININGS,
    SECURITY_OBJECTIVES,
    REQUIREMENTS,
    COMPLIANCE,
    ACCOUNT,
    ACCOUNT_SETTINGS,
    APP,
    EXPERTA,
    COMMON,
    COMPANY_SETTINGS,
    CONSULTANT,
    DISCUSSION,
    DOCUMENT_MANAGEMENT_SYSTEM,
    DOCUMENT_WIZARD,
    ERRORS,
    FORM,
    ISO_27001,
    LANGUAGES,
    MY_WORK,
    ONBOARDING,
    ORGANIZATION,
    PREONBOARDING,
    PRICING,
    REGISTER_WIZARD,
    REGISTERS,
    REGISTERS_AND_MODULES,
    REGULATION,
    REPORTING,
    RESOURCE,
    SETTINGS,
    SLACK,
    STEP_INPUTS,
    TASKS,
    USER_MANAGEMENT,
    VALIDATION,
    TRAINING_PROGRAM
}
