import { ROUTES_AIPT_PREONBOARDING } from '../constants'

export default {
    path: '/aipt-preonboarding',
    name: ROUTES_AIPT_PREONBOARDING.BASE.name,
    props: true,
    component: () => import('./ModulePreonboardingAipt'),
    meta: {
        pageTitle: 'AIPT.PAGE_TITLE',
        requiresAuth: false
    }
}
