import Vue from 'vue'
import Router from 'vue-router'
import { i18n } from '@src/i18n'
import { each } from 'lodash-es'
import store from '@/store'
import CompanySettings from './company-settings'
import RegulationOverview from './regulation'
import ReportingDashboard from './reporting-dashboard'
import Dms from './dms'
import Account from './account'
import { ROUTES, DROPBOX, SHAREPOINT } from '@/constants'
import { authService } from '@/services/auth'
import { ROUTES_AUTH } from '@src/modules/auth/constants'
import RoutesAuth from '@src/modules/auth/router'
import RoutesPreonboarding from '@src/modules/preonboarding/router'
import RoutesCompliance from '@src/modules/compliance/router'
import RoutesTraining from '@src/modules/training/router'
import RoutesConsultant from '@src/modules/consultant/router'
import RoutesMaintenance from '@src/modules/maintenance/router'
import RoutesCompanySettings from '@src/modules/company-settings/router'
import RoutesAipt from '@src/modules/ai-powered-toolkit/router'
import RoutesAiptPreonboarding from '@src/modules/ai-powered-toolkit/preonboarding/router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        if (document.getElementById('main')) {
            document.getElementById('main').scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    },
    routes: [
        {
            path: '/dropbox-redirect',
            redirect: to => ({
                path: to.query.state || '/',
                query: { ...to.query, provider: DROPBOX }
            })
        },
        {
            path: '/sharepoint-redirect',
            redirect: to => ({
                path: to.query.state || '/',
                query: { ...to.query, provider: SHAREPOINT }
            })
        },
        RoutesAuth,
        RoutesConsultant,
        RoutesMaintenance,
        RoutesPreonboarding,
        RoutesAiptPreonboarding,
        {
            path: '/:subdomain?',
            name: ROUTES.HOME.name,
            component: () => import('@/containers/AppHome'),
            meta: {
                requiresAuth: true
            },
            children: [
                Account,
                Dms,
                ReportingDashboard,
                RegulationOverview,
                CompanySettings,
                RoutesCompanySettings,
                RoutesCompliance,
                RoutesTraining,
                RoutesAipt
            ]
        },
        {
            path: '*',
            component: () => import('@/containers/Errors/404')
        }
    ]
})

router.beforeEach((to, from, next) => {
    store.dispatch('router/setOldHistory', to)

    store.dispatch('router/setPreviousRoute', {
        name: from.name,
        meta: from.meta,
        query: from.query,
        params: from.params,
        hash: from.hash
    })

    const isLoggedIn = authService.isLoggedIn()

    if (to.meta.requiresAuth && !isLoggedIn) {
        authService.saveLoginRedirectPath(to)
        next(ROUTES_AUTH.LOGIN)
    }

    if (to.meta.redirectsAuthenticated && isLoggedIn) {
        next(ROUTES.HOME)
    }

    if (to.meta.guards) {
        each(to.meta.guards, guard => {
            guard(to, from, next)
        })
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        if (to.meta.pageTitle) {
            document.title = `Conformio - ${i18n.t(to.meta.pageTitle)}`
        } else {
            document.title = 'Conformio'
        }

        if (to.meta.withScaling) {
            document.documentElement.classList.add('withScaling')
        } else {
            if (document.documentElement.classList.contains('withScaling')) {
                document.documentElement.classList.remove('withScaling')
            }
        }
    })
})

export default router
