<template>
    <app-modal-new
        name="modal-update-company"
        :title="'AIPT.MODALS.UPDATE_COMPANY.TITLE'"
        :confirm-action-label="'AIPT.MODALS.UPDATE_COMPANY.SUBMIT'"
        :confirm-action-disabled="!isSubmitEnabled"
        @handle-confirm="updateCompany"
        @on-show="setData"
        @on-close="resetData"
    >
        <template #content>
            <div class="modal-update-company__wrap">
                <p v-html="$t('AIPT.MODALS.UPDATE_COMPANY.BODY')"></p>

                <app-input-new
                    ref="firstNameRef"
                    autocomplete="first-name"
                    name="first_name"
                    type="text"
                    :label="
                        $t(
                            'PREONBOARDING.ACCOUNT_DETAILS.FORM.FIELDS.FIRST_NAME.LABEL'
                        )
                    "
                    :placeholder="
                        $t(
                            'PREONBOARDING.ACCOUNT_DETAILS.FORM.FIELDS.FIRST_NAME.PLACEHOLDER'
                        )
                    "
                    :error="firstError('first_name')"
                    v-model="form.first_name"
                />

                <app-input-new
                    autocomplete="last-name"
                    name="last_name"
                    type="text"
                    :label="
                        $t(
                            'PREONBOARDING.ACCOUNT_DETAILS.FORM.FIELDS.LAST_NAME.LABEL'
                        )
                    "
                    :placeholder="
                        $t(
                            'PREONBOARDING.ACCOUNT_DETAILS.FORM.FIELDS.LAST_NAME.PLACEHOLDER'
                        )
                    "
                    :error="firstError('last_name')"
                    v-model="form.last_name"
                />

                <app-select-new
                    name="country"
                    :label-content="
                        $t(
                            'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.COUNTRY.LABEL'
                        )
                    "
                    :placeholder="
                        $t(
                            'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.COUNTRY.PLACEHOLDER'
                        )
                    "
                    :options="mappedCountries"
                    :clearable="true"
                    :searchable="true"
                    :error="firstError('country_id')"
                    v-model="form.country_id"
                />

                <app-input-new
                    name="jobTitle"
                    :label="
                        $t(
                            'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.POSITION.LABEL'
                        )
                    "
                    :placeholder="
                        $t(
                            'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.POSITION.PLACEHOLDER'
                        )
                    "
                    :error="firstError('jobTitle')"
                    v-model="form.jobTitle"
                />

                <app-select-new
                    name="selectedPersona"
                    :label-content="
                        $t('PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.ROLE.LABEL')
                    "
                    :placeholder="
                        $t(
                            'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.ROLE.SELECT_PLACEHOLDER'
                        )
                    "
                    :options="mappedPersonas"
                    :clearable="true"
                    :searchable="true"
                    :error="firstError('persona')"
                    v-model="form.selectedPersona"
                />

                <app-input-new
                    v-if="isInputPersonaVisible"
                    name="persona"
                    :placeholder="
                        $t(
                            'PREONBOARDING.COMPANY_SETUP.FORM.FIELDS.ROLE.INPUT_PLACEHOLDER'
                        )
                    "
                    :error="firstError('persona')"
                    v-model="persona"
                />

                <loader-overlay v-if="isLoading" :is-absolute="true" />
            </div>
        </template>
    </app-modal-new>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppInputNew from '@src/components/AppInputNew'
import AppSelectNew from '@src/components/AppSelectNew'
import { toasts } from '@src/mixins'
import { PERSONAS, PERSONA_OTHER } from '@src/modules/preonboarding/constants'
import { ROUTES_COMPLIANCE } from '@src/modules/compliance/constants'

const NOT_MANDATORY_FIELDS = ['label', 'companyName', 'language', 'size']

export default {
    components: {
        AppInputNew,
        AppSelectNew
    },

    mixins: [toasts],

    props: {
        routeObj: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    data: () => ({
        isLoading: true,
        form: {
            first_name: '',
            last_name: '',
            companyName: '',
            size: '',
            jobTitle: '',
            selectedPersona: '',
            country_id: '',
            language: '',
            label: null
        },
        persona: ''
    }),

    computed: {
        ...mapGetters('general', ['countries']),
        ...mapGetters('company', ['company']),
        ...mapGetters('regulation', ['currentRegulation']),
        ...mapGetters('errors', ['firstError']),

        mappedCountries () {
            return this.countries.map(country => ({
                label: country.name,
                value: country.id
            }))
        },

        mappedPersonas () {
            return PERSONAS.map(item => ({
                label: this.$t(item.label),
                value: item.value
            }))
        },

        isInputPersonaVisible () {
            return this.form.selectedPersona === PERSONA_OTHER
        },

        isSubmitEnabled () {
            const array = []

            for (const [key, value] of Object.entries(this.form)) {
                if (!NOT_MANDATORY_FIELDS.some(item => item === key)) {
                    array.push(Boolean(value))
                }
            }

            if (this.form.selectedPersona === PERSONA_OTHER) {
                return array.every(item => item) && this.persona.length
            }

            return array.every(item => item)
        }
    },

    methods: {
        ...mapActions('general', ['getCountries']),
        ...mapActions('account', [
            'updateUser',
            'reloadUserData',
            'getMyPermissions'
        ]),
        ...mapActions('preonboarding', ['createCompany']),
        ...mapActions('company', ['setCompanyData']),

        async setData () {
            if (!this.countries.length) {
                await this.getCountries()
            }

            this.isLoading = false

            this.form.companyName = this.company.name
            this.form.size = this.company.size
            this.form.language = this.company.documentation_language

            setTimeout(() => {
                this.$refs.firstNameRef.$el.querySelector('.app-input').focus()
            })
        },

        resetData () {
            ;(this.form = {
                first_name: '',
                last_name: '',
                companyName: '',
                size: '',
                jobTitle: '',
                selectedPersona: '',
                country_id: '',
                language: '',
                label: null
            }),
                (this.persona = '')

            this.$store.commit('errors/RESET_ERRORS')
        },

        async updateCompany () {
            this.isLoading = true

            const dataToSend = {
                companyName: this.form.companyName,
                company_id: this.company.id,
                size: this.form.size,
                jobTitle: this.form.jobTitle,
                country_id: this.form.country_id,
                language: this.form.language,
                schedule_a_call: false
            }

            if (this.form.selectedPersona === PERSONA_OTHER) {
                dataToSend.persona = this.persona
            } else if (this.form.selectedPersona) {
                dataToSend.persona = this.$t(
                    this.mappedPersonas.find(
                        option => option.value === this.form.selectedPersona
                    ).label
                )
            }

            try {
                await this.updateUser({
                    first_name: this.form.first_name,
                    last_name: this.form.last_name
                })

                // Create company
                await this.createCompany(dataToSend)

                // Reload user data in order to have company member details
                await this.reloadUserData()

                await this.getMyPermissions({
                    companyId: this.company.id,
                    regulationId: this.currentRegulation.id
                })

                this.setCompanyData()

                if (this.routeObj?.name) {
                    this.$router.push(this.routeObj).catch(() => {})
                } else {
                    this.$router
                        .push({
                            name: ROUTES_COMPLIANCE.STEPS.name
                        })
                        .catch(() => {})
                }

                this.showToast(this.$t('COMMON.SUCCESS'))
                this.$modal.hide('modal-update-company')
            } catch (error) {
                this.isLoading = false
                throw error
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.modal-update-company {
    &__wrap {
        position: relative;
        display: flex;
        gap: rem(24);
        flex-direction: column;
    }
}
</style>
