<template>
    <div class="app-sidepanel">
        <div class="app-sidepanel__header">
            <h2 class="app-sidepanel__header-title">Experta</h2>
            <p class="app-sidepanel__header-text">AI-powered ISO assistant</p>
        </div>

        <div id="history" class="app-sidepanel__content">
            <p class="app-sidepanel__loader-msg is-hidden">
                Experta is thinking...
            </p>
        </div>

        <form id="chat-input-form" class="app-sidepanel__form">
            <textarea
                id="userInput"
                class="app-sidepanel__form-input"
                autocomplete="off"
                placeholder="Type your question here"
            ></textarea>
            <button
                id="send-button"
                class="btn btn--alpha app-sidepanel__form-action"
                type="submit"
            >
                Send
            </button>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { initExpertaWidget } from './experta-widget'

export default {
    computed: {
        ...mapGetters('account', ['email'])
    },

    mounted () {
        initExpertaWidget(this.email)
    }
}
</script>

<style lang="scss">
@import '@style/sfc.scss';

.app-sidepanel {
    display: flex;
    height: 100vh;
    width: rem($width-app-sidepanel);
    margin-left: auto;
    flex-direction: column;

    &__header {
        padding: rem(16);
        background-color: $color-primary-beta;
    }

    &__header-title {
        margin: 0;
        font-size: rem(20);
        font-weight: 700;
        line-height: calc(26 / 20);
        color: $color-white;
    }

    &__header-text {
        margin: 0;
        font-size: rem(15);
        line-height: calc(20 / 15);
        color: $color-white;
    }

    &__content {
        @include thin-scrollbar;
        display: flex;
        padding: rem(16) rem(6) rem(8) rem(16);
        gap: rem(16);
        flex: 1;
        flex-direction: column;
        overflow-y: scroll;
        background-color: $color-white;
    }

    &__assistant-message,
    &__user-message {
        max-width: rem(280);
        padding: rem(8);
        border-radius: rem(8);
        font-size: rem(14);
        line-height: calc(19 / 14);
    }

    &__assistant-message {
        max-width: rem(320);
    }

    &__assistant-message {
        padding-right: rem(8);
        align-self: flex-start;
        background-color: $color-primary-zeta;
        color: $color-primary-beta;
    }

    &__assistant-action-message {
        display: block;
        margin-top: rem(4);
        padding: 0;
        border: 0;
        font-size: rem(14);
        line-height: calc(19 / 14);
        text-align: left;
        text-decoration: underline;
        background-color: transparent;
        color: $color-primary-beta;
        cursor: pointer;
        transition: color 0.25s ease;

        &:hover {
            color: $color-primary-epsilon;
        }
    }

    &__user-message {
        margin: rem(8) 0;
        padding-left: rem(8);
        align-self: flex-end;
        background-color: $color-primary-epsilon;
        color: $color-white;
    }

    &__form {
        display: flex;
        padding: rem(16);
        gap: rem(8);
        border-top: 1px solid $color-gray-alpha;
        background-color: $color-white;
        align-items: flex-start;
    }

    &__form-input {
        @include thin-scrollbar;
        min-height: rem(85);
        flex: 1;
        border: 0;
        font-size: rem(14);
        line-height: calc(19 / 14);
        color: $color-primary-beta;
        resize: none;
        outline: none;

        &::placeholder {
            color: $color-primary-beta;
        }
    }

    &__form-action {
        flex-shrink: 0;
    }

    &__loader-msg {
        margin-top: auto;
        margin-bottom: 0;
        order: 1;
        font-size: rem(12);
        font-weight: 700;
        line-height: calc(16 / 12);
        color: $color-primary-beta;

        &.is-hidden {
            display: none;
        }
    }
}
</style>
