export const initExpertaWidget = currentAccount => {
    const expertaAPIBaseURL = 'https://experta.com/api'

    const $loaderEl = document.querySelector('.app-sidepanel__loader-msg')

    updateHistory(
        'Hi there from Experta, ISO 27001 AI Chatbot! How can I help you today?',
        true
    )
    showFAQ([
        'How long will the ISO 27001 implementation last?',
        'How much effort is needed for ISO 27001 implementation?',
        'How to avoid overhead with ISO 27001?'
    ])

    document.addEventListener('contextUpdated', event => {
        updateFAQ(event.detail)
    })

    function updateFAQ (context) {
        if (context.faq) {
            showFAQ(context.faq)
        } else {
            fetch(expertaAPIBaseURL + '/get-related-questions', {
                method: 'POST',
                body: JSON.stringify({
                    text: context.text,
                    namespace: 'questions',
                    max_questions: 3,
                    max_reliability_level: 1
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'X-Api-Key': '413bdc16-f881-4ddd-be28-c996109f45d1',
                    Authorization:
                        'Basic ' + btoa('expertastaging:xyKBEUsWZV0Cr7ON')
                }
            })
                .then(response => response.json())
                .then(faq => {
                    showFAQ(faq)
                })
        }
    }

    // question answering
    document
        .getElementById('chat-input-form')
        .addEventListener('submit', event => {
            event.preventDefault()
            $loaderEl.classList.remove('is-hidden')

            const question = document.getElementById('userInput').value
            document.getElementById('userInput').value = ''

            updateHistory(question, false)

            fetch(expertaAPIBaseURL + '/qa', {
                method: 'POST',
                body: JSON.stringify({
                    question: question,
                    channelId: 'AIPT',
                    email: currentAccount,
                    forceNewConversation: false
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'X-Api-Key': '413bdc16-f881-4ddd-be28-c996109f45d1',
                    Authorization:
                        'Basic ' + btoa('expertastaging:xyKBEUsWZV0Cr7ON')
                }
            })
                .then(response => response.json())
                .then(answerObj => {
                    processChatAnswer(answerObj)
                    $loaderEl.classList.add('is-hidden')
                })
        })

    document.getElementById('userInput').addEventListener('keypress', e => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault()
            document.getElementById('send-button').click()
        }
    })

    function showFAQ (faq) {
        let faqHtml = 'Here are some interesting topics:'
        for (const q of faq) {
            const btn = document.createElement('button')
            btn.className =
                'app-sidepanel__assistant-action-message js-faq-action'
            btn.type = 'button'
            btn.innerHTML = q

            faqHtml += btn.outerHTML
        }
        updateHistory(faqHtml, true)
    }

    function processChatAnswer (answerObj) {
        const formatedAnswer = formatExpertaResponseToHTML(answerObj.answer)
        updateHistory(formatedAnswer, true)
    }

    function updateHistory (htmlToAdd, isBotMessage) {
        const history = document.getElementById('history')
        const hItem = document.createElement('div')

        if (isBotMessage) {
            hItem.classList.add('app-sidepanel__assistant-message')
        } else {
            hItem.classList.add('app-sidepanel__user-message')
        }

        hItem.innerHTML = htmlToAdd

        if (isBotMessage) {
            // remove previous feedback buttons and add new in the response
        }

        history.append(hItem)

        hItem.querySelectorAll('.js-faq-action').forEach(action => {
            action.addEventListener('click', event => {
                askExperta(action.textContent)
            })
        })

        // hide feedback for the first bots message

        // scroll down
        history.scrollTop = history.scrollHeight

        if (isBotMessage) {
            // enable tooltips
        }
    }

    function askExperta (question) {
        document.getElementById('userInput').value = question
        document.getElementById('send-button').click()
    }

    function formatExpertaResponseToHTML (message) {
        try {
            // replace URl with HTML Link
            const urlRegex = /<?(([\w-]+:\/\/?|www[.])[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|)))(?<![.,\?!])>?/gim
            message = message.replace(
                urlRegex,
                '<a href="$1" target="_blank">$1</a>'
            )
            message = message.replaceAll('\n', '<BR>')
            return message
        } catch (error) {
            console.error('Error formatting outgoing message: ', error)
        }
    }
}
