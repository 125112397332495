<template>
    <div
        :class="[
            'app-select',
            {
                'is-invalid': error,
                'is-disabled': disabled
            }
        ]"
    >
        <label
            v-if="labelContent"
            class="app-select__label"
            :for="`app-select-${_uid}`"
        >
            {{ labelContent }}
            <template v-if="tooltipText">
                <app-icon
                    :id="`app-select-tooltip${_uid}`"
                    class="app-select__label-icon"
                    icon="icon-info"
                    size="xs"
                />
                <b-tooltip
                    placement="bottom"
                    :custom-class="
                        `app-tooltip app-tooltip--alpha app-tooltip--${tooltipSize}`
                    "
                    :target="`app-select-tooltip${_uid}`"
                    noninteractive
                >
                    <span v-html="tooltipText"></span>
                </b-tooltip>
            </template>
        </label>

        <p v-if="readonly" class="app-select__readonly-text">
            {{ getValue() }}
        </p>

        <div v-else class="app-select__wrap">
            <v-select
                :id="`app-select-${_uid}`"
                append-to-body
                v-model="selected"
                :name="name"
                :options="options"
                :label="label"
                :clearable="clearable"
                :searchable="searchable"
                :selectable="option => !option.disabled"
                :disabled="disabled"
                :placeholder="
                    placeholder ? placeholder : $t('COMMON.SELECT_OPTION')
                "
                :reduce="reduceFunction"
                :components="{ Deselect, OpenIndicator }"
                :calculate-position="withPopper"
                @input="handleInput"
            >
                <template slot="option" slot-scope="option">
                    <span>
                        {{ option[label] }}
                    </span>
                </template>

                <span slot="no-options" class="app-select__fallback-msg">
                    {{ $t('COMMON.NO_RESULTS') }}
                </span>
            </v-select>

            <app-icon
                v-if="error"
                icon="icon-error"
                size="sm"
                class="app-select__error-icon"
            />
        </div>

        <div v-if="error" class="app-select__hint-wrap">
            <p v-if="error" class="app-select__error">{{ error }}</p>
        </div>

        <div v-if="description" class="app-select__hint-wrap">
            <p class="app-select__description">{{ description }}</p>
        </div>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import AppSelectDeselect from './AppSelectDeselect'
import AppSelectOpenIndicator from './AppSelectOpenIndicator'

export default {
    props: {
        labelContent: {
            type: String,
            required: false,
            default: ''
        },
        tooltipText: {
            type: String,
            required: false,
            default: ''
        },
        tooltipSize: {
            type: String,
            required: false,
            default: '',
            validator (value) {
                // The value must match one of these strings
                return ['', 'md'].includes(value)
            }
        },
        value: {
            type: [String, Number, Object],
            required: false
        },
        name: {
            type: String,
            required: false,
            default: ''
        },
        options: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: 'label'
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        searchable: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        trackBy: {
            type: String,
            required: false,
            default: 'value'
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        error: {
            type: String,
            required: false,
            default: ''
        }
    },

    data: () => ({
        selected: {},
        OpenIndicator: AppSelectOpenIndicator,
        Deselect: AppSelectDeselect
    }),

    computed: {
        reduceFunction () {
            return value => value[this.trackBy]
        }
    },

    watch: {
        value (value) {
            this.selected = value
        },

        options () {
            this.selected = this.value
        }
    },

    created () {
        this.selected = this.value
    },

    methods: {
        withPopper (dropdownList, component, { width }) {
            dropdownList.style.width = width

            const popper = createPopper(component.$refs.toggle, dropdownList, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1]
                        }
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write'
                    }
                ]
            })

            return () => popper.destroy()
        },

        handleInput (selected) {
            this.$emit('input', selected)
        },

        getValue () {
            const option = this.options.find(o => o.value === this.selected)

            return option ? option.label : '-'
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.app-select {
    &.is-invalid {
        .app-select__label {
            color: $color-danger-alpha;
        }

        ::v-deep .vs__dropdown-toggle {
            padding-right: rem(30);
            border-color: $color-danger-alpha;
            color: $color-danger-alpha;

            &::placeholder {
                color: $color-danger-alpha;
            }
        }

        ::v-deep .vs__search {
            &::placeholder {
                color: $color-danger-alpha;
            }
        }

        ::v-deep .vs__clear {
            color: $color-danger-alpha;
        }

        ::v-deep .vs__selected {
            color: $color-danger-alpha;
        }
    }

    &.is-disabled {
        .app-select__label {
            color: $color-gray-beta;
        }

        ::v-deep .vs__dropdown-toggle {
            background-color: $color-gray-alpha;
            border-color: $color-gray-beta;
            color: $color-gray-beta;
            cursor: not-allowed;

            &::placeholder {
                color: $color-gray-beta;
            }
        }

        ::v-deep .vs__search,
        ::v-deep .vs__open-indicator,
        ::v-deep .vs__clear {
            background-color: $color-gray-alpha;
        }

        ::v-deep .vs__search {
            &::placeholder {
                color: $color-gray-beta;
            }
        }

        ::v-deep .vs__clear {
            color: $color-gray-beta;
        }

        ::v-deep .vs__selected {
            color: $color-gray-beta;
        }
    }

    ::v-deep .vs__dropdown-toggle {
        display: flex;
        width: 100%;
        padding: rem(5) rem(2) rem(6) rem(12);
        border: 1px solid $color-gray-alpha;
        border-radius: rem(4);
        font-size: rem(14);
        line-height: calc(21 / 14);
        background-color: $color-white;
        color: $color-primary-beta;
        transition: all 0.25s ease;
    }

    ::v-deep .vs__selected-options {
        flex: 1;
        min-width: 0;
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        display: inline-block;
        margin: 0;
        padding: 0;
        font-size: rem(14);
        line-height: calc(21 / 14);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-primary-beta;
    }

    ::v-deep .vs__search {
        margin: 0;
        padding: 0;
        font-size: rem(14);
        line-height: calc(21 / 14);

        &::placeholder {
            font-weight: 400;
            color: $color-gray-beta;
        }
    }

    ::v-deep .vs--single.vs--open .vs__selected {
        opacity: 0;
    }

    ::v-deep .vs__actions {
        flex-shrink: 0;
        padding-top: rem(3);
        align-self: center;
    }

    ::v-deep .vs__clear {
        color: $color-primary-beta;
    }

    &__wrap {
        position: relative;
    }

    &__label {
        display: flex;
        margin: 0;
        padding-bottom: rem(4);
        gap: rem(5);
        font-size: rem(14);
        font-weight: 700;
        line-height: calc(21 / 14);
        align-items: center;
        color: $color-primary-beta;
    }

    &__label-icon {
        flex-shrink: 0;
        cursor: pointer;
    }

    &__readonly-text {
        margin: 0;
        padding: rem(4) 0;
        font-size: rem(14);
        line-height: calc(21 / 14);
        color: $color-primary-beta;
    }

    &__fallback-msg {
        font-size: rem(14);
        line-height: calc(21 / 14);
        color: $color-primary-beta;
    }

    &__hint-wrap {
        padding-top: rem(4);
    }

    &__description {
        margin: 0;
        font-size: rem(12);
        line-height: calc(18 / 12);
        color: $color-primary-beta;
    }

    &__error {
        margin: 0;
        font-size: rem(12);
        line-height: calc(18 / 12);
        color: $color-danger-alpha;
    }

    &__error-icon {
        position: absolute;
        top: rem(8);
        right: rem(12);
        bottom: 0;
        color: $color-danger-alpha;
    }
}
</style>

<style lang="scss">
@import '@style/sfc.scss';

.vs__dropdown-menu {
    @include thin-scrollbar;
    border-width: 1px;
    border-style: solid;
    border-radius: 0 0 rem(4) rem(4);
    border-color: $color-gray-alpha;

    &[data-popper-placement='top'] {
        border-radius: rem(4) rem(4) 0 0;
    }
}

.vs__dropdown-option {
    padding: rem(5) rem(15);
    font-size: rem(14);
    line-height: calc(21 / 14);
    color: $color-primary-beta;

    &--highlight {
        background-color: $color-primary-zeta;
    }

    &--disabled {
        font-style: normal;
        background-color: $color-white;
        color: $color-gray-beta;
    }
}
</style>
