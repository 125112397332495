<template>
    <div
        :class="[
            'app-input__wrap',
            {
                'is-invalid': error,
                'is-disabled': disabled,
                'with-percentage': showPercentage
            }
        ]"
    >
        <label v-if="label" class="app-input__label" :for="`app-input-${_uid}`">
            <span class="app-input__label-content">{{ label }}</span>
            <template v-if="tooltipText">
                <app-icon
                    :id="`app-input-tooltip${_uid}`"
                    class="app-input__label-icon"
                    icon="icon-info"
                    size="xs"
                />
                <b-tooltip
                    placement="bottom"
                    :custom-class="
                        `app-tooltip app-tooltip--alpha app-tooltip--${tooltipSize}`
                    "
                    :target="`app-input-tooltip${_uid}`"
                    noninteractive
                >
                    {{ tooltipText }}
                </b-tooltip>
            </template>
        </label>

        <p v-if="readonly" class="app-input__readonly-text">
            {{ currentValue ? currentValue : '-' }}
        </p>

        <div v-else class="app-input__input-wrap">
            <span v-if="textBefore" class="app-input__additional-text">{{
                textBefore
            }}</span>

            <div
                :class="[
                    'app-input__input-inner-wrap',
                    {
                        'with-additional-text': textBefore || textAfter
                    }
                ]"
            >
                <input
                    :id="`app-input-${_uid}`"
                    class="app-input"
                    v-model="currentValue"
                    :name="name"
                    :type="type"
                    :placeholder="placeholder"
                    :autofocus="autofocus"
                    :autocomplete="autocomplete"
                    :disabled="disabled"
                    @keydown="handleKeyDown"
                    @focus="$emit('focus')"
                    @blur="$emit('blur')"
                />

                <app-icon
                    v-if="error"
                    icon="icon-error"
                    size="sm"
                    class="app-input__error-icon"
                />

                <span v-if="showPercentage" class="app-input__percentage">
                    <app-icon icon="icon-percentage" size="sm" />
                </span>
            </div>

            <span v-if="textAfter" class="app-input__additional-text">{{
                textAfter
            }}</span>
        </div>

        <div v-if="error" class="app-input__hint-wrap">
            <p class="app-input__error">{{ error }}</p>
        </div>

        <div v-if="description" class="app-input__hint-wrap">
            <p class="app-input__description">{{ description }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        tooltipText: {
            type: String,
            required: false,
            default: ''
        },
        tooltipSize: {
            type: String,
            required: false,
            default: '',
            validator (value) {
                // The value must match one of these strings
                return ['', 'md'].includes(value)
            }
        },
        name: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        value: {
            type: [String, Number],
            required: false,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: false
        },
        autocomplete: {
            type: String,
            required: false,
            default: 'off'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        showPercentage: {
            type: Boolean,
            required: false,
            default: false
        },
        textBefore: {
            type: String,
            required: false,
            default: ''
        },
        textAfter: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        error: {
            type: String,
            required: false,
            default: ''
        }
    },

    data: () => ({
        currentValue: ''
    }),

    watch: {
        value (val) {
            this.currentValue = val
        },

        currentValue (val) {
            this.$emit('input', typeof val === 'string' ? val.trim() : val)
        }
    },

    created () {
        if (this.value) {
            this.currentValue = this.value
        }
    },

    methods: {
        handleKeyDown (e) {
            if (e.target.value.length === 0 && e.code === 'Space') {
                e.preventDefault()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.app-input {
    width: 100%;
    padding: rem(7) rem(12) rem(6);
    border: 1px solid $color-gray-alpha;
    border-radius: rem(4);
    font-size: rem(14);
    line-height: calc(21 / 14);
    background-color: $color-white;
    color: $color-primary-beta;
    transition: all 0.25s ease;

    &::placeholder {
        font-weight: 400;
        color: $color-gray-beta;
    }

    &:focus,
    &:active {
        border-color: $color-primary-gamma;
        color: $color-primary-beta;
        outline: none;

        &::placeholder {
            color: $color-primary-epsilon;
        }
    }

    &:disabled {
        border-color: $color-gray-beta;
        background-color: $color-gray-alpha;
        color: $color-gray-beta;
        cursor: not-allowed;

        &::placeholder {
            color: $color-gray-beta;
        }
    }

    &__wrap {
        &.is-invalid {
            .app-input {
                padding-right: rem(40);
                border-color: $color-danger-alpha;
                color: $color-danger-alpha;

                &::placeholder {
                    color: $color-danger-alpha;
                }
            }

            .app-input__label {
                color: $color-danger-alpha;
            }

            .app-input__percentage {
                border-color: $color-danger-alpha;
            }

            &.with-percentage {
                .app-input__error-icon {
                    right: rem(56);
                }
            }
        }

        &.is-disabled {
            .app-input__label {
                color: $color-gray-beta;
            }
        }
    }

    &__input-wrap {
        display: flex;
        gap: rem(8);
        align-items: center;
    }

    &__input-inner-wrap {
        position: relative;
        flex: 1;

        &.with-additional-text {
            max-width: rem(100);
        }
    }

    &__label {
        display: flex;
        margin: 0;
        padding-bottom: rem(4);
        gap: rem(5);
        font-size: rem(14);
        font-weight: 700;
        line-height: calc(21 / 14);
        align-items: center;
        color: $color-primary-beta;
    }

    &__label-icon {
        flex-shrink: 0;
        cursor: pointer;
    }

    &__percentage {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        padding: 0 rem(12);
        border-width: 1px 1px 1px 0;
        border-radius: 0 rem(4) rem(4) 0;
        border-style: solid;
        border-color: $color-gray-alpha;
        justify-content: center;
        align-items: center;
        background-color: $color-gray-alpha;
        color: $color-primary-beta;
        transition: border-color 0.25s ease;
    }

    &__readonly-text {
        margin: 0;
        padding: rem(4) 0;
        font-size: rem(14);
        line-height: calc(21 / 14);
        color: $color-primary-beta;
    }

    &__additional-text {
        font-size: rem(14);
        line-height: calc(21 / 14);
        color: $color-primary-beta;
    }

    &__hint-wrap {
        padding-top: rem(4);
    }

    &__description {
        margin: 0;
        font-size: rem(12);
        line-height: calc(18 / 12);
        color: $color-primary-beta;
    }

    &__error {
        margin: 0;
        font-size: rem(12);
        line-height: calc(18 / 12);
        color: $color-danger-alpha;
    }

    &__error-icon {
        position: absolute;
        top: 0;
        right: rem(12);
        bottom: 0;
        margin: auto;
        color: $color-danger-alpha;
    }
}
</style>
