const ROUTES_AIPT = {
    BASE: {
        name: 'aipt'
    },
    DOCUMENTS: {
        name: 'aipt.documents'
    },
    DOCUMENT: {
        name: 'aipt.document'
    },
    COMPANY_PROFILE: {
        name: 'aipt.company-profile'
    }
}

const ROUTES_AIPT_PREONBOARDING = {
    BASE: {
        name: 'aipt.preonboarding'
    }
}

const DOWNLOAD_TYPES = {
    EXCEL: 'excel',
    WORD: 'word'
}

const COMPANY_PLACEHOLDERS = {
    confidentiality_label: {
        label: 'AIPT.COMPANY_PROFILE.FORM.FIELDS.CONFIDENTIALITY_LABEL.LABEL',
        additionalText:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.CONFIDENTIALITY_LABEL.ADDITION_TEXT'
    },
    job_title_for_deleting_records: {
        label:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_DELETING_RECORDS.LABEL',
        additionalText:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_DELETING_RECORDS.ADDITION_TEXT'
    },
    job_title_for_distributing_documents: {
        label:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_DISTRIBUTING_DOCUMENTS.LABEL',
        additionalText:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_DISTRIBUTING_DOCUMENTS.ADDITION_TEXT'
    },
    job_title_for_management_document_owner: {
        label:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_MANAGEMENT_DOCUMENT_OWNER.LABEL',
        additionalText:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_MANAGEMENT_DOCUMENT_OWNER.ADDITION_TEXT'
    },
    job_title_for_publishing_documents: {
        label:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_PUBLISHING_DOCUMENTS.LABEL',
        additionalText:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_PUBLISHING_DOCUMENTS.ADDITION_TEXT'
    },
    job_title_for_records_of_external_correspondence: {
        label:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_RECORDS_OF_EXTERNAL_CORRESPONDENCE.LABEL',
        additionalText:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_FOR_RECORDS_OF_EXTERNAL_CORRESPONDENCE.ADDITION_TEXT'
    },
    job_title_of_the_approver: {
        label:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_OF_THE_APPROVER.LABEL',
        additionalText:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.JOB_TITLE_OF_THE_APPROVER.ADDITION_TEXT'
    },
    name_of_the_approver: {
        label: 'AIPT.COMPANY_PROFILE.FORM.FIELDS.NAME_OF_THE_APPROVER.LABEL',
        additionalText:
            'AIPT.COMPANY_PROFILE.FORM.FIELDS.NAME_OF_THE_APPROVER.ADDITION_TEXT'
    }
}

const MODAL_TRY_CONFORMIO_KEY = 'modal-try-conformio-hide'

export {
    ROUTES_AIPT,
    ROUTES_AIPT_PREONBOARDING,
    DOWNLOAD_TYPES,
    COMPANY_PLACEHOLDERS,
    MODAL_TRY_CONFORMIO_KEY
}
