import {
    isEmpty,
    first,
    each,
    pick,
    keys,
    filter,
    find,
    findIndex,
    lowerCase,
    toInteger
} from 'lodash-es'
import { companyService } from '@/services/api/company'
import { COMPANY, COMPANY_SIZES, AUTH_COMPANY_ID } from '@/constants'
import { pricingPlansService } from '@/services/pricing'
import { getLocalStorageItem } from '@/services/localStorage'
import { authService } from '@/services/auth'
import { COMPANY_ROLES } from '@src/modules/company-settings/constants'
import { COMPANY_REGULATION_MAINTENANCE_DATES } from '../../constants/regulations'

function getInitialState () {
    return {
        company: getInitialCompanyData(),
        companyRoles: [],
        logo: '',
        regulations: [],
        users: [],
        paymentDetails: {},
        firstRegulation: {}
    }
}

const state = getInitialState()

const actions = {
    setUserRoles ({ commit, state, rootState, getters }, company) {
        const { user } = rootState.account

        const members = filter(
            company.members,
            member => member.user?.id === user.id
        )

        const member = !isEmpty(members) ? first(members) : null

        const roleData = {
            isProjectManager: member.role.name === COMPANY_ROLES.PROJECT_MANAGER
        }

        commit(
            'account/SET_USER',
            {
                ...state.user,
                ...roleData
            },
            { root: true }
        )
    },

    async getSubscriptionsList ({ commit }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const subscriptionsList = await pricingPlansService.getSubscriptionsList()
            commit('SET_SHOPPING_CARD_DATA', subscriptionsList)
        } catch (error) {
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async updateCompanySoaProgress ({ commit, rootGetters, dispatch }, value) {
        const companyId = rootGetters['company/company'].id

        commit('SET_APP_LOADING', true, { root: true })
        try {
            const company = await companyService.updateCompanySoaProgress(
                companyId,
                value || null
            )
            commit('SET_COMPANY_SOA_PROGRESS', company)
        } catch (error) {
            dispatch('errors/handleErrorNoToast', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async cancelSubsriptionAction ({ commit, rootGetters, dispatch }, payload) {
        const companyId = rootGetters['company/company'].id

        commit('SET_APP_LOADING', true, { root: true })
        try {
            await companyService.cancelSubscription(companyId, payload)
            commit('RESET_COMPANY')
            await dispatch('getCompany')
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getCompany ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const user = rootGetters['account/user']

            const authCompanyId = JSON.parse(
                getLocalStorageItem(AUTH_COMPANY_ID)
            )

            if (!user || (user.isConsultant && !authCompanyId)) {
                return
            }

            const companies = await companyService.getAll()

            let company

            if (authCompanyId) {
                company = companies.find(
                    company => company.id === toInteger(authCompanyId)
                )
            } else if (companies.length) {
                company = first(companies)
            }

            if (company) {
                commit('SET_COMPANY', company)
                dispatch('setCompanyData')

                await dispatch(
                    'account/getMyPermissions',
                    {
                        companyId: company
                            ? company.id
                            : getLocalStorageItem(AUTH_COMPANY_ID),
                        regulationId:
                            rootGetters['regulation/currentRegulation'].id
                    },
                    { root: true }
                )
            }
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async setCompanyData ({ commit, dispatch, getters }) {
        const company = getters.company

        commit('SET_PROJECT_MANAGER', company)

        dispatch('setUserRoles', company)

        commit('SET_USERS', { companyMembers: company.members })

        commit(
            'account/SET_LOCALE',
            { locale: company.documentation_language },
            { root: true }
        )
    },

    async getCompanyRoles ({ commit, getters }, company) {
        commit('SET_APP_LOADING', true, { root: true })

        if (!company) {
            company = getters.company
        }

        try {
            const response = await companyService.getCompanyRoles(company.id)
            commit('SET_COMPANY_ROLES', response)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async createOrUpdateCompany ({ commit, getters, dispatch }, data) {
        const { isCompanyExpired } = getters

        if (!isCompanyExpired) {
            commit('SET_APP_LOADING', true, { root: true })

            const { owner_id, ...rest } = data // eslint-disable-line
            try {
                const company = getters.company

                const response = company.id
                    ? await companyService.update(company.id, rest)
                    : await companyService.create(rest)

                commit('SET_COMPANY', response)

                return company
            } catch (error) {
                dispatch('errors/handleError', error, { root: true })
                throw error
            } finally {
                commit('SET_APP_LOADING', false, { root: true })
            }
        }
    },

    async updateCompanyMfa ({ commit, getters, dispatch }, value) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const { company } = getters

            const updatedCompany = await companyService.update(company.id, {
                mfa_required: value
            })

            commit('SET_COMPANY', updatedCompany)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async submitLogo ({ commit, getters }, formData) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const response = await companyService.submitLogo(
                getters.company.id,
                formData
            )
            commit('SET_COMPANY', response)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async deleteLogo ({ commit, getters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            await companyService.deleteLogo(getters.company.id)
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async markAsHowToVideoWatched ({ commit, dispatch, getters }, member) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const company = getters.company
            const memberData = await companyService.updateMember(
                company.id,
                member.id
            )
            member.howto_video_watched = memberData.howto_video_watched
            commit('SET_ACCOUNT_AS_VIDEO_SEEN', member)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async stepByStepWizardVideoWatched ({ commit, dispatch, getters }, member) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const company = getters.company
            const memberData = await companyService.updateMemberStepByStepWizardVideoWatched(
                company.id,
                member.id
            )

            member.step_by_step_wizard_video_watched =
                memberData.step_by_step_wizard_video_watched

            commit('SET_ACCOUNT_AS_VIDEO_SEEN', member)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async myWorkVideoWatched ({ commit, dispatch, getters }, member) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const company = getters.company
            const memberData = await companyService.updateMemberMyWorkVideoWatched(
                company.id,
                member.id
            )

            member.my_work_video_watched = memberData.my_work_video_watched

            commit('SET_ACCOUNT_AS_VIDEO_SEEN', member)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async introVideoWatched ({ commit, dispatch, getters }, member) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const company = getters.company
            const memberData = await companyService.updateMemberIntroVideoWatched(
                company.id,
                member.id
            )

            member.intro_video_watched = memberData.intro_video_watched

            commit('SET_ACCOUNT_AS_VIDEO_SEEN', member)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async maintenanceVideoWatched ({ commit, dispatch, getters }, member) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const company = getters.company
            const memberData = await companyService.updateMemberMaintenanceVideoWatched(
                company.id,
                member.id
            )

            member.intro_video_watched = memberData.intro_video_watched

            commit('SET_ACCOUNT_AS_VIDEO_SEEN', member)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getCompanyPermissions ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })

        try {
            const companyId = rootGetters['company/company'].id
            let companyPermissions = await companyService.getCompanyPermissions(
                companyId
            )
            commit('SET_COMPANY_PERMISSIONS', companyPermissions)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getPaymentDetails ({ commit, rootGetters, dispatch }) {
        try {
            const companyId = rootGetters['company/company'].id

            let paymentDetails = await companyService.getPaymentDetails(
                companyId
            )

            commit('SET_COMPANY_PAYMENT_DETAILS', paymentDetails)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        }
    },

    async updateProjectManager (
        { commit, rootGetters, dispatch, getters },
        projectManagerEmail
    ) {
        try {
            const company = rootGetters['company/company']
            await companyService.updateProjectManagerRequest(
                company.id,
                getters.regulationData.id,
                projectManagerEmail
            )
            commit('SET_PROJECT_MANAGER', company)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async updateScheduledOnboardingCall ({ rootGetters }) {
        const companyId = rootGetters['company/company'].id

        try {
            await companyService.updateScheduledOnboardingCall(companyId)
        } catch (error) {
            throw error
        }
    }
}

const mutations = {
    SET_COMPANY (state, company) {
        state.company = {
            ...state.company,
            ...company
        }

        authService.setLocalStorageCompanyData(company.id, company.subdomain)

        state.regulations = company.regulations ? [...company.regulations] : []
    },

    SET_COMPANY_SOA_PROGRESS (state, company) {
        state.company.soa_progress = company.soa_progress
    },

    SET_PROJECT_MANAGER (state, company) {
        const regulation = first(company.regulations)
        const managerId = regulation ? regulation.pivot.manager_id : null // eslint-disable-line
        if (!managerId) {
            state.company.projectManagerForCurrentRegulation = null
        }

        const member = find(company.members, member => member.id === managerId)

        if (member) {
            state.company.projectManagerForCurrentRegulation = member
        } else {
            const firstProjectManager = company.members.find(
                member => member.role.name === COMPANY_ROLES.PROJECT_MANAGER
            )
            state.company.projectManagerForCurrentRegulation = firstProjectManager
        }
    },

    SET_USERS (state, { companyMembers }) {
        state.users = companyMembers.map(companyMember => {
            return {
                ...companyMember.user,
                job_title: companyMember.job_title
            }
        })
    },

    SET_LOGO (state, logo) {
        state.logo = logo
    },

    SET_COMPANY_PLAN (state, pricingPlan) {
        state.company = {
            ...state.company,
            pricing_plan: pricingPlan
        }
    },

    SET_COMPANY_FILE_SYSTEM (state, fileSystem) {
        state.company = {
            ...state.company,
            file_system: fileSystem
        }
    },

    UPDATE_REGULATION (state, { regulationId, regulation }) {
        const index = findIndex(state.regulations, { id: regulationId })

        if (index === -1) {
            return
        }

        state.regulations.splice(index, 1, regulation)
    },

    UPDATE_REGULATION_MAINTENANCE_DATES (
        state,
        { regulationId, maintenanceDates }
    ) {
        const regulation = find(state.company.regulations, { id: regulationId })

        regulation.pivot = {
            ...regulation.pivot,
            ...pick(maintenanceDates, COMPANY_REGULATION_MAINTENANCE_DATES)
        }
    },

    RESET_COMPANY (state) {
        const s = getInitialState()
        each(keys(state), key => {
            state[key] = s[key]
        })
    },

    SET_ACCOUNT_AS_VIDEO_SEEN (state, member) {
        let arrIndex = state.company.members.findIndex(function (arr) {
            return arr.id === member.id
        })
        if (arrIndex !== undefined) {
            state.company.members[arrIndex] = member
        }
    },

    SET_COMPANY_PERMISSIONS (state, planPermissions) {
        state.planPermissions = planPermissions
    },

    SET_COMPANY_PAYMENT_DETAILS (state, paymentDetails) {
        state.paymentDetails = paymentDetails
    },

    SET_COMPANY_TAX_NUMBER (state, taxNumber) {
        state.company.tax = taxNumber
    },

    SET_COMPANY_ROLES (state, companyRoles) {
        state.companyRoles = companyRoles
    },

    SET_SHOPPING_CARD_DATA (state, data) {
        state.subscriptionsList = data
    }
}

const getters = {
    company: state => state.company,
    companyExists: state => !!state.company.id,
    isOnboardingCompleted: state => !!state.company.is_onboarding_completed,
    companyRoles: state => state.companyRoles,
    projectManagerForCurrentRegulation: state =>
        state.company.projectManagerForCurrentRegulation,
    owner: state =>
        find(
            state.company.members,
            member => member.user?.id === state.company.owner.id
        ),
    departments: state => state.company.departments,
    companySize: state => state.company.size,
    regulation: state =>
        !isEmpty(state.company.regulations)
            ? first(state.company.regulations).name
            : '',
    firstRegulation: state =>
        !isEmpty(state.company.regulations)
            ? first(state.company.regulations)
            : '',
    regulationData: state =>
        !isEmpty(state.company.regulations)
            ? first(state.company.regulations)
            : null,
    logo: state => state.logo,
    regulations: state => state.regulations,
    taskSystem: state => state.company.task_system,
    soaProgress: state => state.company.soa_progress,
    companyHasMoreThanFiftyEmployees: (state, getters) =>
        getters.companySize &&
        getters.companySize !== COMPANY_SIZES.FROM_ZERO_TO_TWENTY_EMPLOYEES &&
        getters.companySize !==
            COMPANY_SIZES.FROM_TWENTY_ONE_TO_FIFTY_EMPLOYEES,
    companyAdmin: state => state.company.owner,
    users: state => state.users,
    activeCompanyMembers: state =>
        state.company.members.filter(mu => mu.activity_status),
    activeUsers: (state, getters) => {
        return getters.activeCompanyMembers
            .filter(member => member.user)
            .map(member => member.user)
    },
    userById: state => id => find(state.users, { id }),
    usersById: state =>
        state.users.reduce(
            (reducer, user) => ({ ...reducer, [user.id]: user }),
            {}
        ),
    companyMemberById: (state, getters) => id =>
        find(getters.companyMembers, { id }),
    regulationById: state => id => find(state.company.regulations, { id }),
    register: state => state.register,
    companyMembers: (state, getters, rootState, rootGetters) => {
        return state.company.members.map(member => {
            const user = find(state.users, { id: member.user?.id })

            return {
                ...member,
                user
            }
        })
    },
    activatedAndActiveMembers: state => {
        return state.company.members.filter(
            managedUser =>
                managedUser.activity_status && !isEmpty(managedUser.user)
        )
    },
    activatedMembers: state => {
        return state.company.members.filter(
            managedUser => !isEmpty(managedUser.user)
        )
    },
    companyMemberByRole: (state, getters) => role => {
        const member = find(getters.companyMembers, member => {
            return !!find(member.company_roles, { name: role })
        })

        return member
    },
    getCompanyPermissionsKeys: state => state.planPermissions,
    isCompanyExpired: state => state.company.freezed !== 0,
    pricingPlan: state => state.company.pricing_plan,
    pricingPlanLimits: state => state.company?.pricing_plan.subscription.limits,
    isSubscriptionTierName: state => tierName => {
        return (
            state.company?.pricing_plan?.subscription.tier &&
            lowerCase(
                state.company?.pricing_plan?.subscription.tier.tier_name
            ) === lowerCase(tierName)
        )
    },
    isStarterTier: (state, getters) => {
        return getters.isSubscriptionTierName(COMPANY.TIERS.CONFORMIO_STARTER)
    },
    isAdvancedTier: (state, getters) => {
        return (
            getters.isSubscriptionTierName(COMPANY.TIERS.ADVANCED) ||
            getters.isSubscriptionTierName(COMPANY.TIERS.CONFORMIO_ADVANCED)
        )
    },
    isProfessionalTier: (state, getters) => {
        return getters.isSubscriptionTierName(
            COMPANY.TIERS.CONFORMIO_PROFESSIONAL
        )
    },
    paymentDetails: state => state.paymentDetails,
    subscriptionsList: state => state.subscriptionsList,
    companyHasEarlyAccess: state => state.company.early_access,
    companyLogo: state => {
        return (
            (state.company.has_whitelabel && state.company.whitelabel_logo) ||
            require('@/assets/logo-white.png')
        )
    },
    isMfaRequired: state => state.company.mfa_required
}

function getInitialCompanyData () {
    return {
        id: null,
        owner: null,
        documentation_language: '',
        subdomain: '',
        regulation: '',
        name: '',
        logo_url: '',
        country_id: '',
        size: '',
        file_system: '',
        communication_system: '',
        task_system: '',
        members: [],
        departments: [],
        soa_progress: [],
        tax: 0,
        is_onboarding_completed: false,
        mfa_required: false,
        toolkit_company: null
    }
}

export const company = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
