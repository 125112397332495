<template>
    <div>
        <nav class="app-nav" @mouseover="mouseover" @mouseleave="mouseleave">
            <div ref="topBar" class="app-nav__top-wrap">
                <button
                    class="app-nav__home-action"
                    type="button"
                    @click="handleNavigateHome"
                >
                    <div
                        v-if="companyHasWhiteLabelLogo"
                        class="app-nav__whitelabel-wrap"
                    >
                        <img
                            v-if="company.whitelabel_logo"
                            class="app-nav__whitelabel-img app-nav__whitelabel-img--big"
                            :src="company.whitelabel_logo"
                        />
                        <img
                            v-else
                            class="app-nav__whitelabel-img app-nav__whitelabel-img--big"
                            :src="require(`@src/assets/images/logo.png`)"
                        />

                        <img
                            v-if="company.whitelabel_logo_small"
                            class="app-nav__whitelabel-img app-nav__whitelabel-img--small"
                            :src="company.whitelabel_logo_small"
                        />
                        <img
                            v-else
                            class="app-nav__whitelabel-img app-nav__whitelabel-img--small"
                            :src="require(`@src/assets/images/logo-small.png`)"
                        />
                    </div>
                    <img
                        v-else
                        class="app-nav__logo-img"
                        :src="require(`@src/assets/images/logo.png`)"
                    />
                </button>
                <p class="app-nav__standard">
                    <app-icon icon="icon-privacy" size="sm" />
                    <span class="app-nav__standard_text">{{
                        $t('COMMON.ISO_27001')
                    }}</span>
                </p>
            </div>

            <ul
                v-for="(list, i) in navigationLists"
                :key="i"
                :class="[
                    `app-nav__list app-nav__list--is-${list.additionalClass}`
                ]"
                :ref="list.id"
                :style="{
                    height:
                        list.id === PRIMARY_NAV
                            ? `calc(100vh - ${topBarHeight +
                                  secondaryNavHeight}px)`
                            : 'auto'
                }"
            >
                <li
                    :class="[
                        `app-nav__list-item app-nav__list-item--is-${list.additionalClass}`,
                        {
                            'app-nav__list-item--is-label': item.isLabel
                        }
                    ]"
                    v-for="item in list.navigation"
                    :key="item.id"
                >
                    <div
                        v-if="item.isLabel"
                        class="app-nav__action app-nav__action--is-label"
                    >
                        <span
                            class="app-nav__action-text app-nav__action-text--is-label"
                        >
                            {{ item.label }}
                            <span
                                class="app-nav__action-tag"
                                v-if="item.haveTag"
                                >{{ item.tag }}</span
                            >
                        </span>
                    </div>

                    <button
                        v-else-if="item.url"
                        :class="[
                            'app-nav__action',
                            {
                                'app-nav__action--is-active':
                                    $route.name === item.url.name
                            }
                        ]"
                        type="button"
                        @click="goToUrl(item.url)"
                    >
                        <app-icon
                            v-if="item.icon"
                            :icon="`icon-${item.icon}`"
                            size="sm"
                        />
                        <span class="app-nav__action-text">
                            {{ item.label }}
                            <span
                                class="app-nav__action-tag"
                                v-if="item.haveTag"
                                >{{ item.tag }}</span
                            >
                        </span>
                    </button>

                    <button
                        v-else
                        :class="[
                            'app-nav__action',
                            {
                                'app-nav__action--is-sub-nav-expanded': !!item.subNavigationMaxHeight
                            }
                        ]"
                        type="button"
                        @click="item.action"
                    >
                        <app-icon
                            v-if="item.icon"
                            :icon="`icon-${item.icon}`"
                            size="sm"
                        />
                        <span class="app-nav__action-text">{{
                            item.label
                        }}</span>
                        <app-icon
                            v-if="item.isExpandable"
                            icon="icon-arrow"
                            size="custom"
                        />
                    </button>

                    <ul
                        v-if="item.isExpandable"
                        :ref="item.id"
                        class="app-nav__sub-list"
                        :style="{
                            'max-height': `${item.subNavigationMaxHeight}px`
                        }"
                    >
                        <li
                            class="app-nav__sub-list-item"
                            v-for="(subItem, j) in item.subNavigation"
                            :key="j"
                        >
                            <button
                                v-if="subItem.url"
                                :class="[
                                    'app-nav__sub-action',
                                    {
                                        'app-nav__sub-action--is-active':
                                            $route.name === subItem.url.name
                                    }
                                ]"
                                type="button"
                                @click="goToUrl(subItem.url)"
                            >
                                <span class="app-nav__sub-action-text">
                                    {{ subItem.label }}
                                    <span
                                        class="app-nav__action-tag"
                                        v-if="subItem.haveTag"
                                        >{{ subItem.tag }}</span
                                    >
                                </span>
                            </button>
                            <button
                                v-else
                                class="app-nav__sub-action"
                                type="button"
                                @click="subItem.action"
                            >
                                <span class="app-nav__sub-action-text">{{
                                    subItem.label
                                }}</span>
                            </button>
                        </li>
                    </ul>
                </li>
                <li
                    v-if="list.id === secondaryNavId"
                    :class="[
                        `app-nav__list-item app-nav__list-item--is-${list.additionalClass}`
                    ]"
                >
                    <p class="app-nav__copyright">
                        © {{ new Date().getFullYear() }}
                        <span>Advisera Expert Solutions Ltd</span>
                    </p>
                </li>
            </ul>
        </nav>

        <modal-update-company :route-obj="routeObj" />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { mapValues, values } from 'lodash-es'
import { EventBus } from '@src/EventBus'
import {
    MY_PERMISSIONS,
    REGISTERS_PERMISSIONS,
    HELP_CENTER_LINK,
    HELP_VIDEOS_LINK,
    ROUTES,
    REGISTER_WIZARD_ROUTES,
    DMS_ROUTES,
    ACCOUNT_ROUTES,
    COMPANY_SETTINGS_ROUTES,
    STANDARD_TRANSLATION_KEYS,
    ISO_27001_ROUTE_PARAM,
    COMPANY
} from '@src/constants'
import { ROUTES_TRAINING } from '@src/modules/training/constants'
import { ROUTES_COMPLIANCE } from '@src/modules/compliance/constants'
import { ROUTES_CONSULTANT } from '@src/modules/consultant/constants'
import { ROUTES_AIPT } from '@src/modules/ai-powered-toolkit/constants'
import { ROUTES_SECURITY_OBJECTIVES } from '@src/modules/registers/security-objectives/constants'
import { permissionControl } from '@src/mixins'
import { routingService } from '@src/services/routing'
import {
    hasPermission,
    isRegistersOwner
} from '@src/services/permissions/my-permissions'
import {
    initialExpandedSubNavigation,
    PRIMARY_NAV,
    SECONDARY_NAV,
    NAVIGATION_IDS
} from './utils'
import ModalUpdateCompany from '@src/modules/ai-powered-toolkit/modals/ModalUpdateCompany'

export default {
    components: {
        ModalUpdateCompany
    },

    data: () => ({
        secondaryNavId: SECONDARY_NAV,
        topBarHeight: 0,
        secondaryNavHeight: 0,
        primaryExpandedNav: {
            type: PRIMARY_NAV,
            id: '',
            maxHeight: 0
        },
        secondaryExpandedNav: {
            type: SECONDARY_NAV,
            id: '',
            maxHeight: 0
        },
        animationTimeout: null,
        mouseoverEvent: false,
        routeObj: {}
    }),

    mixins: [permissionControl],

    computed: {
        ...mapState('account', ['user', 'myPermissions']),
        ...mapState('company', ['company']),
        ...mapGetters('account', ['isConsultant', 'requireTermsOfUse']),
        ...mapGetters('company', [
            'companyExists',
            'isOnboardingCompleted',
            'regulationData',
            'pricingPlan'
        ]),
        ...mapGetters('loading', ['companyFetched']),
        ...mapGetters('regulation', ['currentRegulation', 'steps']),

        regulationTranslation () {
            return STANDARD_TRANSLATION_KEYS[ISO_27001_ROUTE_PARAM]
        },

        companyHasWhiteLabelLogo () {
            return (
                this.company.has_whitelabel &&
                Boolean(
                    this.company.whitelabel_logo ||
                        this.company.whitelabel_logo_small
                )
            )
        },

        navigationLists: {
            get () {
                const navigation = [
                    {
                        id: PRIMARY_NAV,
                        additionalClass: PRIMARY_NAV,
                        navigation: [
                            {
                                id: NAVIGATION_IDS.CONSULTANT_DASHBOARD,
                                isVisible:
                                    (this.isConsultant &&
                                        !this.isOnboardingCompleted) ||
                                    !this.companyFetched ||
                                    !this.companyExists,
                                isExpandable: false,
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.CONSULTANT.DASHBOARD'
                                ),
                                icon: 'briefcases',
                                url: ROUTES_CONSULTANT.COMPANIES
                            },
                            {
                                id: 'aipt',
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    Boolean(this.company.toolkit_company),
                                isLabel: true,
                                isExpandable: false,
                                label: this.$t('APP.SIDEBAR_ROUTES.LABELS.AIPT')
                            },
                            {
                                id: NAVIGATION_IDS.DOCUMENTATION_TOOLKIT,
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    Boolean(this.company.toolkit_company),
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.DOCUMENTATION_TOOLKIT.LABEL'
                                ),
                                icon: 'folders',
                                url: routingService.generateRoute(
                                    ROUTES_AIPT.DOCUMENTS
                                )
                            },
                            {
                                id: NAVIGATION_IDS.COMPANY_PROFILE,
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    Boolean(this.company.toolkit_company),
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.COMPANY_PROFILE.LABEL'
                                ),
                                icon: 'suitcase',
                                url: routingService.generateRoute(
                                    ROUTES_AIPT.COMPANY_PROFILE
                                )
                            },
                            {
                                id: 'conformio',
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    Boolean(this.company.toolkit_company),
                                isLabel: true,
                                isExpandable: false,
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.LABELS.CONFORMIO'
                                ),
                                tag: this.$t('APP.SIDEBAR_TAGS.FREE_TRIAL'),
                                haveTag:
                                    this.pricingPlan?.plan?.name ===
                                    COMPANY.FREE_PLAN
                            },
                            {
                                id: NAVIGATION_IDS.COMPLIANCE,
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    this.canAccessSidebarItem([
                                        MY_PERMISSIONS.IMPLEMENTATION_STEPS_ACCESS,
                                        MY_PERMISSIONS.AUDIT_AND_EVIDENCE_ACCESS
                                    ]),
                                isExpandable: true,
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.COMPLIANCE.LABEL'
                                ),
                                icon: 'storage',
                                action: () =>
                                    this.expandSubNavigation(
                                        PRIMARY_NAV,
                                        'compliance'
                                    ),
                                subNavigationMaxHeight:
                                    this.primaryExpandedNav.id === 'compliance'
                                        ? this.primaryExpandedNav.maxHeight
                                        : 0,
                                subNavigation: [
                                    {
                                        label: this.$t(
                                            'APP.SIDEBAR_ROUTES.COMPLIANCE.SUB_NAVIGATION.MAIN_STEPS'
                                        ),
                                        isVisible: this.canAccessSidebarItem([
                                            MY_PERMISSIONS.IMPLEMENTATION_STEPS_ACCESS
                                        ]),
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES_COMPLIANCE.STEPS.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            'APP.SIDEBAR_ROUTES.COMPLIANCE.SUB_NAVIGATION.AUDIT_AND_EVIDENCE'
                                        ),
                                        isVisible: this.canAccessSidebarItem([
                                            MY_PERMISSIONS.AUDIT_AND_EVIDENCE_ACCESS
                                        ]),
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES_COMPLIANCE.AUDIT_AND_EVIDENCE
                                                .name
                                        ),
                                        tag: this.$t('APP.SIDEBAR_TAGS.NEW')
                                    },
                                    {
                                        label: this.$t(
                                            'APP.SIDEBAR_ROUTES.COMPLIANCE.SUB_NAVIGATION.MAINTENANCE'
                                        ),
                                        isVisible: this.canAccessSidebarItem([
                                            MY_PERMISSIONS.MAINTENANCE_ACCESS
                                        ]),
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES_COMPLIANCE.MAINTENANCE.name
                                        ),
                                        tag: this.$t('APP.SIDEBAR_TAGS.NEW'),
                                        haveTag: true
                                    }
                                ]
                            },
                            {
                                id: NAVIGATION_IDS.DOCUMENTS,
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    this.canAccessSidebarItem([
                                        MY_PERMISSIONS.DOCUMENT_EXPLORER_ACCESS
                                    ]),
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.DOCUMENTS.LABEL'
                                ),
                                icon: 'folders',
                                url: routingService.generateRoute(
                                    DMS_ROUTES.FILE_EXPLORER
                                )
                            },
                            {
                                id: NAVIGATION_IDS.REGISTERS_AND_MODULES,
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    this.canAccessSidebarItem([
                                        MY_PERMISSIONS.REGISTER_AND_MODULES_ACCESS
                                    ]),
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.REGISTERS_AND_MODULES.LABEL'
                                ),
                                icon: 'database',
                                url: routingService.generateStandardBasedRoute(
                                    ROUTES.REGISTERS_AND_MODULES.name
                                )
                            },
                            {
                                id: NAVIGATION_IDS.REPORTING_DASHBOARD,
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    (this.canAccessSidebarItem([
                                        MY_PERMISSIONS.REPORTING_DASHBOARD_ACCESS
                                    ]) ||
                                        this.isUserRegistersOwner([
                                            REGISTERS_PERMISSIONS.SECURITY_OBJECTIVES,
                                            REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                                        ])),
                                isExpandable: true,
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.REPORTING_DASHBOARD.LABEL'
                                ),
                                icon: 'lab-profile',
                                action: () =>
                                    this.expandSubNavigation(
                                        PRIMARY_NAV,
                                        'reportingDashboard'
                                    ),
                                subNavigationMaxHeight:
                                    this.primaryExpandedNav.id ===
                                    'reportingDashboard'
                                        ? this.primaryExpandedNav.maxHeight
                                        : 0,
                                subNavigation: [
                                    {
                                        label: this.$t(
                                            'APP.SIDEBAR_ROUTES.REPORTING_DASHBOARD.LABEL'
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            REGISTER_WIZARD_ROUTES.REPORTING
                                                .INDEX.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            `REPORTING.OVERVIEW.PROJECT_STATUS.TITLE.${this.regulationTranslation}`
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            REGISTER_WIZARD_ROUTES.REPORTING
                                                .PROJECT_STATUS_DASHBOARD.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            `REPORTING.OVERVIEW.COMPLIANCE.TITLE.${this.regulationTranslation}`
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            REGISTER_WIZARD_ROUTES.REPORTING
                                                .COMPLIANCE_DASHBOARD.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            `REPORTING.OVERVIEW.PERFORMANCE.TITLE.${this.regulationTranslation}`
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            REGISTER_WIZARD_ROUTES.REPORTING
                                                .PERFORMANCE_DASHBOARD.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            'REPORTING.OVERVIEW.SECURITY_OBJECTIVES'
                                        ),
                                        isVisible:
                                            this.canAccessSidebarItem([
                                                MY_PERMISSIONS.REPORTING_DASHBOARD_ACCESS
                                            ]) ||
                                            this.isUserRegistersOwner([
                                                REGISTERS_PERMISSIONS.SECURITY_OBJECTIVES
                                            ]),
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES_SECURITY_OBJECTIVES.BASE.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            'REPORTING.OVERVIEW.MANAGEMENT_REVIEW'
                                        ),
                                        isVisible:
                                            this.canAccessSidebarItem([
                                                MY_PERMISSIONS.REPORTING_DASHBOARD_ACCESS
                                            ]) ||
                                            this.isUserRegistersOwner([
                                                REGISTERS_PERMISSIONS.MANAGEMENT_REVIEWS
                                            ]),
                                        url: routingService.generateStandardBasedRoute(
                                            REGISTER_WIZARD_ROUTES.REPORTING
                                                .MANAGEMENT_REVIEW.ACTIVITIES
                                                .WIZARD.name
                                        )
                                    }
                                ]
                            },
                            {
                                id: NAVIGATION_IDS.RESPONSIBILITY_MATRIX,
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    this.canAccessSidebarItem([
                                        MY_PERMISSIONS.RESPONSIBILITY_MATRIX_ACCESS
                                    ]),
                                isExpandable: false,
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.RESPONSIBILITY_MATRIX.LABEL'
                                ),
                                icon: 'dashboard',
                                url: routingService.generateStandardBasedRoute(
                                    ROUTES.RESPONSIBILITY_MATRIX.INDEX.name
                                )
                            },
                            {
                                id: NAVIGATION_IDS.WORK,
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    this.canAccessSidebarItem([
                                        MY_PERMISSIONS.MY_WORK_ACCESS
                                    ]),
                                isExpandable: true,
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.MY_WORK.LABEL'
                                ),
                                icon: 'assignment',
                                action: () =>
                                    this.expandSubNavigation(
                                        PRIMARY_NAV,
                                        'work'
                                    ),
                                subNavigationMaxHeight:
                                    this.primaryExpandedNav.id === 'work'
                                        ? this.primaryExpandedNav.maxHeight
                                        : 0,
                                subNavigation: [
                                    {
                                        label: this.$t(
                                            'APP.SIDEBAR_ROUTES.MY_WORK.LABEL'
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES.MY_WORK.OVERVIEW.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            'MY_WORK.TASKS.MY_TASKS.TITLE'
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES.MY_WORK.TASKS.MY_TASKS.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            'MY_WORK.TASKS.TASKS_FOR_OTHERS.TITLE'
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES.MY_WORK.TASKS
                                                .TASKS_FOR_OTHERS.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            'MY_WORK.STEPS.SIDEBAR_TITLE'
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES.MY_WORK.STEPS.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            'MY_WORK.DOCUMENTS.TITLE'
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES.MY_WORK.DOCUMENTS.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            'MY_WORK.DISCUSSIONS.CARD_TITLE'
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES.MY_WORK.DISCUSSIONS.name
                                        )
                                    },
                                    {
                                        label: this.$t(
                                            'MY_WORK.REGISTERS.TITLE'
                                        ),
                                        isVisible: true,
                                        url: routingService.generateStandardBasedRoute(
                                            ROUTES.MY_WORK.REGISTERS.name
                                        )
                                    }
                                ]
                            },
                            {
                                id: NAVIGATION_IDS.TRAINING_PROGRAM,
                                isVisible: this.canAccessSidebarItem([
                                    MY_PERMISSIONS.TRAINING_ACCESS
                                ]),
                                isExpandable: false,
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.TRAINING_PROGRAM.LABEL'
                                ),
                                icon: 'training',
                                url: ROUTES_TRAINING.BASE,
                                tag: this.$t('APP.SIDEBAR_TAGS.FREE'),
                                haveTag: true
                            },
                            {
                                id: NAVIGATION_IDS.COMPANY_SETTINGS,
                                isVisible:
                                    this.companyFetched &&
                                    this.companyExists &&
                                    this.canAccessSidebarItem([
                                        MY_PERMISSIONS.USER_MANAGEMENT_ACCESS,
                                        MY_PERMISSIONS.BASIC_INFORMATION_ACCESS,
                                        MY_PERMISSIONS.PROJECT_SETTINGS_ACCESS,
                                        MY_PERMISSIONS.INTEGRATIONS_ACCESS,
                                        MY_PERMISSIONS.SECURITY_ACCESS,
                                        MY_PERMISSIONS.SUBSCRIPTIONS_ACCESS
                                    ]),
                                isExpandable: true,
                                label: this.$t(
                                    'APP.SIDEBAR_ROUTES.COMPANY_SETTINGS.LABEL'
                                ),
                                icon: 'settings',
                                action: () =>
                                    this.expandSubNavigation(
                                        PRIMARY_NAV,
                                        'companySettings'
                                    ),
                                subNavigationMaxHeight:
                                    this.primaryExpandedNav.id ===
                                    'companySettings'
                                        ? this.primaryExpandedNav.maxHeight
                                        : 0,
                                subNavigation: [
                                    ...this.companySettingsNavigation
                                ]
                            }
                        ]
                    },
                    {
                        id: SECONDARY_NAV,
                        additionalClass: SECONDARY_NAV,
                        navigation: [
                            {
                                id: NAVIGATION_IDS.HELP,
                                isVisible: this.requireTermsOfUse,
                                isExpandable: true,
                                label: this.$t('COMMON.HELP'),
                                icon: 'help',
                                action: () =>
                                    this.expandSubNavigation(
                                        SECONDARY_NAV,
                                        'help'
                                    ),
                                subNavigationMaxHeight:
                                    this.secondaryExpandedNav.id === 'help'
                                        ? this.secondaryExpandedNav.maxHeight
                                        : 0,
                                subNavigation: [
                                    {
                                        label: this.$t('ACCOUNT.HELP_ARTICLES'),
                                        isVisible: true,
                                        action: () =>
                                            window.open(
                                                HELP_CENTER_LINK,
                                                '_blank'
                                            )
                                    },
                                    {
                                        label: this.$t('ACCOUNT.HELP_VIDEOS'),
                                        isVisible: true,
                                        action: () =>
                                            window.open(
                                                HELP_VIDEOS_LINK,
                                                '_blank'
                                            )
                                    },
                                    {
                                        label: this.$t('ACCOUNT.CONTACT_US'),
                                        isVisible: true,
                                        action: () =>
                                            this.$modal.show('payment-support')
                                    }
                                ]
                            },
                            {
                                id: NAVIGATION_IDS.ACCOUNT,
                                isVisible: true,
                                isExpandable: true,
                                label: this.$t('COMMON.MY_ACCOUNT'),
                                icon: 'account',
                                action: () =>
                                    this.expandSubNavigation(
                                        SECONDARY_NAV,
                                        'account'
                                    ),
                                subNavigationMaxHeight:
                                    this.secondaryExpandedNav.id === 'account'
                                        ? this.secondaryExpandedNav.maxHeight
                                        : 0,
                                subNavigation: [
                                    {
                                        label: this.$t('ACCOUNT.MY_PROFILE'),
                                        isVisible: this.requireTermsOfUse,
                                        url: routingService.generateStandardBasedRoute(
                                            ACCOUNT_ROUTES.MY_PROFILE.name
                                        )
                                    },
                                    {
                                        label: this.$t('ACCOUNT.PREFERENCES'),
                                        isVisible: this.requireTermsOfUse,
                                        url: routingService.generateStandardBasedRoute(
                                            ACCOUNT_ROUTES.SETTINGS.name
                                        )
                                    },
                                    {
                                        label: this.$t('ACCOUNT.ABOUT'),
                                        isVisible: true,
                                        action: () =>
                                            this.$modal.show('about-modal')
                                    },

                                    {
                                        label: this.$t(
                                            'ACCOUNT.SWITCH_COMPANY'
                                        ),
                                        isVisible:
                                            this.isConsultant &&
                                            this.companyFetched &&
                                            this.companyExists,
                                        url: ROUTES_CONSULTANT.COMPANIES
                                    },
                                    {
                                        label: this.$t('ACCOUNT.LOGOUT'),
                                        isVisible: true,
                                        action: () => EventBus.$emit('logout')
                                    }
                                ]
                            }
                        ]
                    }
                ]

                const navigationMapping = navigation.map(item => {
                    return {
                        ...item,
                        navigation: item.navigation
                            .map(navItem => {
                                if (navItem.subNavigation) {
                                    return {
                                        ...navItem,
                                        subNavigation: navItem.subNavigation.filter(
                                            subNavItem => subNavItem.isVisible
                                        )
                                    }
                                }

                                return {
                                    ...navItem
                                }
                            })
                            .filter(navItem => navItem.isVisible)
                    }
                })

                return navigationMapping
            },
            set (newExpandedNav) {
                newExpandedNav.type === PRIMARY_NAV
                    ? (this.primaryExpandedNav = newExpandedNav)
                    : (this.secondaryExpandedNav = newExpandedNav)
            }
        }
    },

    watch: {
        $route (to, from) {
            if (from.name === ROUTES_CONSULTANT.COMPANIES.name) {
                this.mouseoverEvent = false
                this.resetExpandedNav()
            }

            if (to.name === ROUTES_CONSULTANT.COMPANIES.name) {
                this.resetExpandedNav()
            }
        }
    },

    created () {
        this.PRIMARY_NAV = PRIMARY_NAV
        this.SECONDARY_NAV = SECONDARY_NAV
    },

    updated () {
        if (
            this.$refs.topBar &&
            this.$refs[PRIMARY_NAV] &&
            this.$refs[SECONDARY_NAV]
        ) {
            if (
                this.topBarHeight !== this.$refs.topBar.offsetHeight &&
                this.secondaryNavHeight !==
                    this.$refs[SECONDARY_NAV][0].offsetHeight
            ) {
                this.topBarHeight = this.$refs.topBar.offsetHeight
                this.secondaryNavHeight = this.$refs[
                    SECONDARY_NAV
                ][0].offsetHeight
            }
        }

        if (
            this.$refs[this.primaryExpandedNav.id] &&
            this.$refs[this.primaryExpandedNav.id].length
        ) {
            this.primaryExpandedNav.maxHeight = this.$refs[
                this.primaryExpandedNav.id
            ][0].scrollHeight
        }

        if (
            this.$refs[this.secondaryExpandedNav.id] &&
            this.$refs[this.secondaryExpandedNav.id].length
        ) {
            this.secondaryExpandedNav.maxHeight = this.$refs[
                this.secondaryExpandedNav.id
            ][0].scrollHeight
        }
    },

    methods: {
        ...mapActions('account', ['redirectHome']),

        mouseover () {
            if (!this.mouseoverEvent) {
                // setTimeout 300 miliseconds only for animation purposes, imitates transition
                this.animationTimeout = setTimeout(() => {
                    this.expandNav()
                }, 300)
                this.mouseoverEvent = true
            }
        },

        mouseleave () {
            clearTimeout(this.animationTimeout)
            this.resetExpandedNav()
            this.mouseoverEvent = false
        },

        async handleNavigateHome () {
            if (
                !this.isOnboardingCompleted ||
                this.$route.name === ROUTES_CONSULTANT.COMPANIES.name
            ) {
                return
            }

            await this.redirectHome()
        },

        expandSubNavigation (nav, id) {
            if (nav === PRIMARY_NAV) {
                this.navigationLists =
                    this.primaryExpandedNav.id === id
                        ? {
                              type: PRIMARY_NAV,
                              id: '',
                              maxHeight: 0
                          }
                        : {
                              type: PRIMARY_NAV,
                              id: id,
                              maxHeight: this.$refs[id][0].scrollHeight
                          }
            } else if (nav === SECONDARY_NAV) {
                this.navigationLists =
                    this.secondaryExpandedNav.id === id
                        ? {
                              type: SECONDARY_NAV,
                              id: '',
                              maxHeight: 0
                          }
                        : {
                              type: SECONDARY_NAV,
                              id: id,
                              maxHeight: this.$refs[id][0].scrollHeight
                          }
            }
        },

        canAccessSidebarItem (permissions) {
            const array = []

            permissions.forEach(permission =>
                array.push(hasPermission(permission))
            )

            return this.user && array.some(item => item)
        },

        isUserRegistersOwner (registers) {
            const array = []

            registers.forEach(register =>
                array.push(isRegistersOwner(register))
            )

            return this.user && array.some(item => item)
        },

        resetExpandedNav () {
            this.primaryExpandedNav = {
                type: PRIMARY_NAV,
                id: '',
                maxHeight: 0
            }

            this.secondaryExpandedNav = {
                type: SECONDARY_NAV,
                id: '',
                maxHeight: 0
            }
        },

        expandNav () {
            if (
                !this.primaryExpandedNav.maxHeight &&
                !this.secondaryExpandedNav.maxHeight
            ) {
                const expandData = initialExpandedSubNavigation(
                    this.$route.name
                )

                if (expandData) {
                    this.expandSubNavigation(expandData.nav, expandData.id)
                }
            }
        },

        isAiptRoute (route) {
            const routeName = route.name

            const aiptRoutes = values(mapValues(ROUTES_AIPT, 'name'))

            return aiptRoutes.includes(routeName)
        },

        goToUrl (route) {
            if (
                Boolean(this.company.toolkit_company) &&
                !this.isOnboardingCompleted
            ) {
                if (this.isAiptRoute(route)) {
                    this.$router.push(route).catch(() => {})
                } else {
                    this.routeObj = route
                    this.$modal.show('modal-update-company')
                }
            } else {
                this.$router.push(route).catch(() => {})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.app-nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    width: rem($width-app-navigation-collapsed);
    height: 100vh;
    z-index: $z-index-app-navigation;
    flex-direction: column;
    background-color: $color-primary-beta;
    transition: width 0.3s ease;

    &__top-wrap {
        padding: rem(24) rem(20);
        border-bottom: 1px solid $cf-color-primary-light;
    }

    &__logo-img {
        height: auto;
        min-width: rem(100);
        transition: min-width 0.3s ease;
    }

    &__home-action {
        display: flex;
        height: rem(46);
        width: rem(34);
        margin: 0 0 rem(40);
        padding: 0;
        border: 0;
        align-items: center;
        background-color: transparent;
        overflow: hidden;
        transform: translateX(#{rem(-7)});
        transition: width 0.3s ease;
    }

    &__whitelabel-wrap {
        height: rem(45);
        width: rem(45);
        transition: height 0.4s ease, width 0.4s ease;
    }

    &__whitelabel-img {
        height: 100%;
        object-fit: contain;
        transition: width 0.4s ease, opacity 0.4s ease;

        &--big {
            width: 0;
            opacity: 0;
        }

        &--small {
            width: 100%;
        }
    }

    &__standard {
        display: flex;
        margin: 0;
        gap: rem(12);
        align-items: center;
        font-size: rem(20);
        font-weight: 700;
        color: $color-white;
    }

    &__standard_text {
        width: 0;
        opacity: 0;
        white-space: nowrap;
        overflow: hidden;
        transition: opacity 0.2s ease 0.3s;
    }

    &__list {
        padding: rem(20) 0;
        list-style-type: none;

        &--is-primary {
            overflow-y: overlay;

            // Hide scrollbar
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none; /* Safari and Chrome */
            }
        }

        &--is-secondary {
            margin-top: auto;
            border-top: 1px solid $cf-color-primary-light;
        }
    }

    &__list-item {
        margin-right: rem(8);
        padding: rem(7) 0;

        &--is-secondary {
            padding: rem(4) 0;
        }

        &--is-label {
            max-height: rem(0);
            margin-right: 0;
            padding: 0;
            overflow: hidden;

            &:not(:first-of-type) {
                padding-top: rem(20);
                margin-top: rem(10);
                border-top: 1px solid $cf-color-primary-light;
            }
        }
    }

    &__action {
        display: flex;
        width: 100%;
        padding: rem(4) 0 rem(4) rem(20);
        gap: rem(7);
        border: 0;
        border-radius: rem(40) 999em 999em rem(40); // pill shape radius
        font-size: rem(16);
        font-weight: 700;
        line-height: calc(22 / 16);
        text-align: left;
        align-items: center;
        background-color: transparent;
        color: $color-white;
        transition: background-color 0.25s ease, color 0.25s ease;

        .cf-c-icon:not(.cf-c-icon-arrow) {
            flex-shrink: 0;
        }

        .cf-c-icon-arrow {
            position: relative;
            top: rem(1);
            height: rem(8);
            width: 0;
            opacity: 0;
            overflow: hidden;
            transition: transform 0.25s ease, opacity 0.2s ease 0.3s;

            ::v-deep .cf-c-icon--custom {
                height: rem(8);
                width: rem(12);
            }
        }

        &--is-active {
            background-color: $cf-color-primary-light;
            color: $cf-color-primary-dark;
        }

        &--is-sub-nav-expanded {
            .cf-c-icon-arrow {
                transform: rotate(180deg);
            }
        }

        &--is-label {
            font-size: rem(18);
            line-height: calc(22 / 18);
        }

        &:hover:not(.app-nav__action--is-label) {
            background-color: $cf-color-primary-light;
            color: $cf-color-primary-dark;
        }
    }

    &__sub-list {
        max-height: 0;
        overflow: hidden;
        list-style-type: none;
        transition: all 0.4s ease;
    }

    &__sub-list-item {
        padding: rem(4) 0;
    }

    &__sub-action {
        display: inline-block;
        width: 100%;
        padding: rem(4) rem(20) rem(4) rem(50);
        border: 0;
        border-radius: rem(40) 999em 999em rem(40); // pill shape radius
        font-size: rem(14);
        line-height: calc(19 / 14);
        text-align: left;
        background-color: transparent;
        color: $color-white;
        transition: background-color 0.25s ease, color 0.25s ease;

        &--is-active {
            background-color: $cf-color-primary-light;
            color: $cf-color-primary-dark;
        }

        &:hover {
            background-color: $cf-color-primary-light;
            color: $cf-color-primary-dark;
        }
    }

    &__action-text,
    &__sub-action-text {
        max-height: rem(22);
        width: 0;
        opacity: 0;
        overflow: hidden;

        &--is-label {
            display: flex;
            gap: rem(10);
            align-items: center;

            .app-nav__action-tag {
                margin-top: 0;
            }
        }
    }

    &__action-tag {
        display: block;
        width: fit-content;
        margin-top: rem(5);
        padding: rem(2) rem(10);
        border-radius: rem(4);
        font-size: rem(12);
        font-weight: 700;
        line-height: calc(15 / 12);
        text-transform: uppercase;
        background-color: $color-primary-alpha;
        color: $color-white;
    }

    &__copyright {
        margin: 0;
        padding-left: rem(12);
        font-size: rem(10);
        line-height: calc(18 / 10);
        color: $color-white;
        white-space: nowrap;
        overflow: hidden;
        transition: padding-left 0.2s ease;

        span {
            display: inline-block;
            width: 0;
            opacity: 0;
            transition: opacity 0.2s ease 0.3s;
        }
    }

    &:hover {
        width: rem($width-app-navigation);

        .app-nav__home-action {
            width: rem(175);
            transform: translateX(0px);
        }

        .app-nav__logo-img {
            min-width: rem(175);
        }

        .app-nav__whitelabel-wrap {
            height: rem(50);
            width: rem(170);
        }

        .app-nav__whitelabel-img {
            &--big {
                width: 100%;
                opacity: 1;
            }

            &--small {
                width: 0;
                opacity: 0;
            }
        }

        .app-nav__list-item {
            &--is-label {
                max-height: 100vh;
                padding: rem(7) 0;
                transition: max-height 2s ease, padding 0.2s ease;

                &:not(:first-of-type) {
                    padding-top: rem(20);
                }
            }
        }

        .app-nav__copyright {
            padding-left: rem(20);
        }

        .app-nav__standard_text,
        .app-nav__copyright span {
            width: auto;
            opacity: 1;
        }

        .cf-c-icon-arrow {
            width: rem(12);
            opacity: 1;
        }

        .app-nav__action-text,
        .app-nav__sub-action-text {
            max-height: 100vh;
            width: auto;
            opacity: 1;
            transition: max-height 2s ease 0.4s, opacity 0.2s ease 0.3s;
        }

        .app-nav__sub-action-text {
            white-space: normal;
        }
    }
}
</style>
