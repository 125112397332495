import { ROUTES_AIPT } from './constants'

export default {
    path: 'aipt',
    name: ROUTES_AIPT.BASE.name,
    props: true,
    redirect: ROUTES_AIPT.DOCUMENTS,
    component: () => import('./ModuleAipt'),
    meta: {
        requiresAuth: true
    },
    children: [
        {
            path: 'documents',
            name: ROUTES_AIPT.DOCUMENTS.name,
            component: () => import('./AiptDocuments'),
            props: true,
            meta: {
                pageTitle: 'AIPT.PAGE_TITLE',
                requiresAuth: true
            }
        },
        {
            path: 'document/:documentId',
            name: ROUTES_AIPT.DOCUMENT.name,
            component: () => import('./AiptDocument'),
            props: true,
            meta: {
                pageTitle: 'AIPT.PAGE_TITLE',
                requiresAuth: true
            }
        },
        {
            path: 'company-profile',
            name: ROUTES_AIPT.COMPANY_PROFILE.name,
            component: () => import('./AiptCompanyProfile'),
            props: true,
            meta: {
                pageTitle: 'AIPT.PAGE_TITLE',
                requiresAuth: false
            }
        }
    ]
}
